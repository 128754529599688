require('./bootstrap');

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');

require('bootstrap');
require('./js_web');

// window.simpleParallax = require('simple-parallax-js');s
require('./PureCounter');

require('./quill.js');
window.Quill = require('Quill');

window.DataTable = require('datatables.net');
