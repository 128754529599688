import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';
import Block from 'quill/formats/blockquote';
import Inline from 'quill/formats/underline';
import Image from 'quill/formats/image';
import Formula from 'quill/modules/formula';
import Syntax from 'quill//modules/syntax';


Quill.register({
	'modules/toolbar': Toolbar,
	'modules/formula': Formula,
	'modules/formula': Syntax,

	'themes/snow': Snow,

	'formats/bold': Bold,
	'formats/italic': Italic,
	'formats/header': Header,
	'formats/block': Block,
	'formats/inline': Inline,
	'formats/image': Image,
});


export default Quill;
